<template>
  <div>
    <b-container class="mt-4">
      <b-row class="mt-3 mb-4">
        <b-col class="text-center h7">
          Personal data
        </b-col>
      </b-row>
      <b-row
        class="m-0 content-holder p-3"
      >
        <b-col class="mt-3 mb-4">
          <b-col
            class="mb-4"
            cols="12"
            lg="7"
          >
            <label class="h8">First name
              <span class="text-primary pl-1">*</span>
            </label>
            <FormTextInput
              v-model="((agency.users || [])[0] || {}).first_name"
              placeholder="John"
              required
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
            lg="7"
          >
            <label class="h8">Last name
              <span class="text-primary pl-1">*</span>
            </label>
            <FormTextInput
              v-model="((agency.users || [])[0] || {}).last_name"
              placeholder="Doe"
              required
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
            lg="7"
          >
            <label class="h8">Email
              <span class="text-primary pl-1">*</span>
            </label>
            <FormTextInput
              v-model="((agency.users || [])[0] || {}).email"
              placeholder="john@doe.com"
            />
          </b-col>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="mt-4">
      <b-row class="mt-3 mb-4">
        <b-col class="text-center h7">
          Agency data
        </b-col>
      </b-row>
      <b-row
        class="m-0 content-holder p-3"
      >
        <b-col class="mt-3 mb-4">
          <b-row>
            <b-col
              class="mb-4"
              cols="12"
              lg="7"
            >
              <label class="h8">Agency name
                <span class="text-primary pl-1">*</span>
              </label>
              <FormTextInput
                v-model="agency.name"
                placeholder="Agency name"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              lg="7"
            >
              <label class="h8">Agency LinkedIn Profile(URL)
                <span class="text-primary pl-1">*</span>
              </label>
              <FormTextInput
                v-model="agency.linkedin"
                name="linkedin"
                placeholder="https://linkedin.com/in/123"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              lg="7"
            >
              <label class="h8">Agency Website(URL)
              </label>
              <FormTextInput
                v-model="agency.website"
                name="website"
                placeholder="https://youragency.com"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              lg="7"
            >
              <label class="h8">Where are you based?
                <span class="text-primary pl-1">*</span>
              </label>
              <b-form-select
                v-model="agency.country"
                :options="countryOptions"
                class="select-small-font"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8"># of Recruiters / Sourcers
                <span class="text-primary pl-1">*</span>
              </label>
              <BadgeCheckbox
                v-model="agency.employees"
                name="employees"
                :options="employeesOptions"
                required
                :limit="1"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8">
                Role preference
                <span class="font-weight-normal">(roles you are interested in recruiting)</span>
                <span class="text-primary pl-1">*</span>
              </label>
              <BadgeCheckbox
                v-model="agency.roles"
                name="roles"
                :options="filledRolesOptions"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">Services provided – how can you help companies?
                <span class="text-primary pl-1">*</span>
              </label>
              <FormTags
                v-model="agency.services"
                name="services"
                :options="servicesOptions"
                button-text="Select services that apply"
                class="borderless-tags"
                require
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">Industry preference
                <span class="font-weight-normal">(companies you are interested in)</span>
                <span class="text-primary pl-1">*</span>
              </label>
              <FormTags
                v-model="agency.industries"
                name="industries"
                :options="industryOptions"
                button-text="Select industries that apply"
                class="borderless-tags"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">Region expertise
                <span class="font-weight-normal">(regions you've hired in)</span>
                <span class="text-primary pl-1">*</span>
              </label>
              <FormTags
                v-model="agency.regions"
                name="regions"
                :options="regionsOptions"
                button-text="Select countries"
                class="borderless-tags"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">Country expertise
                <span class="text-primary pl-1">*</span>
              </label>
              <p class="h9">
                Name countries you’ve proven to deliver great results
              </p>
              <FormTags
                v-model="agency.local_insights"
                name="local_insights"
                :options="countryOptions"
                button-text="Select countries"
                class="borderless-tags"
                required
              />
            </b-col>

            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">
                Availability
                <span class="text-primary">*</span>
              </label>
              <p class="h9">
                Are you currently available to take on new recruiting projects?
              </p>
              <b-form-group>
                <b-form-radio-group
                  v-model="agency.availability"
                  class="test"
                >
                  <b-form-radio value="Available">
                    <p class="pt-1 h9">
                      Available
                    </p>
                  </b-form-radio>
                  <b-form-radio value="Not available">
                    <p class="pt-1 h9">
                      Not available
                    </p>
                  </b-form-radio>
                  <b-form-radio value="available_from">
                    <p class="pt-1 h9">
                      Available from
                    </p>
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-datepicker
                v-if="agency.availability === 'available_from' || (agency.availability !== 'Available' && agency.availability !== 'Not available')"
                v-model="available_from"
                class="datepicker"
                name="datepicker"
                size="sm"
                placeholder="Choose Date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: undefined }"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="8"
            >
              <label class="h8">
                Previous clients/employers
              </label>
              <p class="h9">
                Name only some of the trophy clients:
              </p>
              <FormTextArea
                v-model="agency.previous_clients"
                name="previous_clients"
                placeholder="e.g I have worked for a Rainbow Unicorn EdTech startup as an HR Business Partner. I have also hired for Y Combniator startups like Cloudify."
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8">
                Your preferred compensation model
                <span class="text-primary">*</span>
              </label>
              <p class="h9">
                Select as many as suitable
              </p>
              <BadgeCheckbox
                v-model="agency.pricing_models"
                name="pricing_models"
                :options="feeOptions"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8">
                Pricing
                <span class="text-primary">*</span>
              </label>
              <p class="h9">
                Select as many as suitable
              </p>
              <BadgeCheckbox
                v-model="agency.fee_ranges"
                name="fee_ranges"
                :options="feeRangeOptions"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8">
                Working language(s)*
              </label>
              <FormTags
                v-model="agency.languages"
                name="languages"
                :options="languageOptions"
                button-text="Working language(s)"
                class="borderless-tags"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8">
                Fluency in English
                <span class="text-primary">*</span>
              </label>
              <BadgeCheckbox
                v-model="agency.english_level"
                name="english_level"
                :options="englishFluencyOptions"
                required
                :limit="1"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">
                Agency one-liner
                <span class="text-primary">*</span>
              </label>
              <FormTextArea
                v-model="agency.title"
                name="title"
                required
                :rows="1"
                placeholder="e.g. Recruiting agency with 7 years experince in recruiting senior developers"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">Agency logo</label>
              <p class="h9">
                If you don't want to add your logo, you'll get a cute illustration instead
              </p>
              <b-form-group>
                <b-img
                  v-if="agency.logo"
                  :src="agency.logo"
                  class="agency_logo mb-2"
                  rounded="circle"
                />
                <label
                  for="agency_logo"
                  class="agency_logo_upload"
                  :style="{ backgroundImage: 'url(' + agency.logo + ')' }"
                />
                <b-form-file
                  id="agency_logo"
                  class="agency_logo"
                  :state="Boolean(agency.logo)"
                  accept=".jpg, .jpeg, .png, .gif"
                  name="agency_logo"
                  size="sm"
                  placeholder="Add a logo"
                  drop-placeholder="Drop file here..."
                  @input="uploadPic"
                />
              </b-form-group>
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">
                Description
                <span class="text-primary">*</span>
              </label>
              <p class="h9">
                What makes you special? Describe your superskills and distinctive experiences with 3-5 sentences. Good luck! 💪
              </p>
              <FormTextArea
                v-model="agency.description"
                name="description"
                required
                :rows="3"
                placeholder="e.g We're the best recruiter the world has ever seen. We can fill all non-tech roles in 2 weeks and all tech roles in 1 week."
              />
            </b-col>
            <b-col
              class="text-center h7 p-0 mt-4 mb-2"
              cols="12"
            >
              What are your goals and expectations?
            </b-col>
            <b-col
              class="text-center mb-3"
              cols="12"
            >
              <p class="second-headline h9">
                Tell us about your expectations so we know how we can help you the most.
              </p>
            </b-col>
            <b-row
              cols="1"
              cols-sm="3"
              align-h="center"
              class="text-center ml-5 mr-5 h9 mb-6"
            >
              <b-col
                v-for="goal in goalOptions"
                :key="goal.id"
                class="answer-box text-center ml-2 mr-2 mb-4 pt-1"
                :class="{ active: agency.goals.indexOf(goal.id) > -1 }"
                @click="toggleGoal(goal.id)"
              >
                <p class="m-4">
                  {{ goal.label }}
                </p>
              </b-col>
              <b-col
                class="answer-box ml-2 mr-2 mb-4 pt-1"
                :class="{ active: otherActive }"
                @click="otherActive = !otherActive"
              >
                <p class="m-4">
                  Other (enter your answer)
                </p>
              </b-col>
              <b-textarea
                v-if="otherActive"
                id="other"
                v-model="agency.other_goal"
                autofocus
                name="other"
                rows="4"
                class="col answer-box ml-2 mr-2 mb-4 pt-1"
                placeholder="Enter text here"
              />
            </b-row>
            <b-row>
              <b-col class="text-center mb-2">
                <b-btn
                  variant="primary"
                  type="submit"
                  @click="saveAgency"
                >
                  Save
                </b-btn>
              </b-col>
            </b-row>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FormTextInput from '@/components/form/TextInput'
import FormTextArea from '@/components/form/TextArea'
import FormTags from '@/components/FormTags'
import BadgeCheckbox from '@/components/form/BadgeCheckbox'
import axios from '@/helpers/axios'

const SERVICE_OPTIONS_ORDER = {
  'Recruitment planning': 1,
  'Market research': 2,
  'Acquisition strategy': 3,
  Sourcing: 4,
  'Screening calls': 5,
  Interviewing: 6,
  'Pipeline management': 7,
  'Recruitment marketing': 8,
  'Employer branding': 9,
  'Salary negotiations': 10,
  'Recruitment software implementation': 11,
  'Recruitment process development': 12,
}

const GEO_REGIONS_ORDER = {
  Globally: 1,
  'North America': 2,
  'Central America': 3,
  'South America': 4,
  'The Caribbean': 5,
  Africa: 6,
  'Western Europe': 7,
  'Northern Europe': 8,
  'Eastern Europe': 9,
  'Southern Europe': 10,
  'Middle East': 11,
  'Central Asia': 12,
  'East Asia': 13,
  'South Asia': 14,
  'Southeast Asia': 15,
  Oceania: 16,
}
const SENIORITY_OPTIONS_ORDER = {
  Entry: 1,
  Junior: 2,
  'Mid-level': 3,
  Senior: 4,
  Expert: 5,
}

const PRICING_MODEL_OPTIONS = {
  'Hourly fee': 1,
  'Fixed fee with pre-payment': 2,
  'Contingency (% of the annual salary)': 3,
  Other: 4,
}

const PRICING_RANGE_OPTIONS = {
  '< €30/h': 1,
  '€30/h - €60/h': 2,
  '€60/h - €90/h': 3,
  '> €90/h': 4,
  '< €3000 per hire': 5,
  '€3000 - €6000 per hire': 6,
  '€6000 - €9000 per hire': 7,
  '> €9000 per hire': 8,
  '< 12% of annual salary': 9,
  '12% - 20% of annual salary': 10,
  '> 20% of annual salary': 11,
}

export default {
  name: 'AgencySignupDetailsStep1',
  components: {
    FormTextInput,
    FormTags,
    BadgeCheckbox,
    FormTextArea,
  },
  data () {
    return {
      agency: {},
      user: {},
      countryOptions: [],
      recruiterTypes: [
        { text: 'Agency', value: 'Agency' },
        { text: 'Freelancer', value: 'Freelancer' },
        { text: 'Interim', value: 'Interim' },
        { text: 'In-house', value: 'In-house' },
      ],
      servicesOptions: [],
      industryOptions: [],
      regionsOptions: [],
      filledRolesOptions: [],
      employeesOptions: [
        { text: '2-10', value: '2-10' },
        { text: '11-50', value: '11-50' },
        { text: '51-200', value: '51-200' },
        { text: '201-500', value: '201-500' },
        { text: '501-1000', value: '501-1000' },
        { text: '1001-5000', value: '1001-5000' },
        { text: '5001-10000', value: '5001-10000' },
        { text: '10000+', value: '10000+' },
      ],
      available_from: null,
      feeOptions: [],
      feeRangeOptions: [],
      languageOptions: [],
      englishFluencyOptions: [
        { text: 'Basic', value: 'Basic' },
        { text: 'Conversational', value: 'Conversational' },
        { text: 'Fluent', value: 'Fluent' },
        { text: 'Native or bilingual', value: 'Native or bilingual' },
      ],
      otherActive: null,
      otherGoal: null,
      goalOptions: [],
    }
  },
  computed: {

    bestAtRoles () {
      return this.filledRolesOptions.filter(r => this.roles.includes(r.id))
    },

    goalsDisabled () {
      return this.agency.goals.length === 0 || this.submitting === true
    },
  },
  created () {
    axios
      .get(`/v2/agencies/${this.$route.params.id}`)
      .then(({ data }) => {
        data.country = (data.country || {}).id
        data.roles = (data.roles || []).map(r => r.id)
        data.services = (data.services || []).map(r => r.id)
        data.industries = (data.industries || []).map(r => r.id)
        data.regions = (data.regions || []).map(r => r.id)
        data.local_insights = (data.local_insights || []).map(r => r.id)
        data.pricing_models = (data.pricing_models || []).map(r => r.id)
        data.fee_ranges = (data.fee_ranges || []).map(r => r.id)
        data.languages = (data.languages || []).map(r => r.id)
        data.goals = (data.goals || []).map(r => r.id)
        data.logo = data.logo || '/images/file_upload_agency.svg'
        if (data.other_goal) {
          this.otherActive = true
        }
        // Check if availability is date, then make available_from as date too
        if (data.availability && data.availability !== 'Available' && data.availability !== 'Not available') {
          this.available_from = data.availability
          // this.available_from has been updated to date, now let's change recruiter one to "available_from"
          // So we can show that radio button checked
          data.availability = 'available_from'
        }
        this.agency = data
      })
    axios.get('/public/v2/values/countries')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        temp.unshift({ value: null, text: 'Select country' })
        this.countryOptions = temp
      })
    axios.get('/public/v2/values/services')
      .then(({ data }) => {
        // Creating temporary variable where to hold data/options
        let temp = this.mapValues(data)
        // mapping through temp and adding order key + value
        temp = temp.map(option => {
          option.order = SERVICE_OPTIONS_ORDER[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.servicesOptions = temp
      })
    axios.get('/public/v2/values/industries')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        let temp = this.mapValues(data)
        // finding "Other" option obj. in array
        const findOtherOption = temp.find(industry => industry.text === 'Other')
        // Getting "Other" option obj. index in array
        const otherOptionIndex = temp.findIndex(industry => industry.text === 'Other')
        // Erasing "Other" obj from array
        temp.splice(otherOptionIndex, 1)
        // Sorting array by alphabetical order
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        // Adding "Other" option at the end of array
        temp.push(findOtherOption)
        // renaming/overwriting temporary variable
        this.industryOptions = temp
      })
    axios.get('/public/v2/values/regions')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.map(option => {
          option.order = GEO_REGIONS_ORDER[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.regionsOptions = temp
      })
    axios.get('/public/v2/values/roles')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        let temp = this.mapValues(data)
        // finding "Other" option obj. in array
        const findOtherOption = temp.find(role => role.text === 'Other')
        // Getting "Other" option obj. index in array
        const otherOptionIndex = temp.findIndex(role => role.text === 'Other')
        // Erasing "Other" obj from array
        temp.splice(otherOptionIndex, 1)
        // Sorting array by alphabetical order
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        // Adding "Other" option at the end of array
        temp.push(findOtherOption)
        // renaming/overwriting temporary variable
        this.filledRolesOptions = temp
        this.roleFocusOptions = temp
      })
    axios.get('/public/v2/values/pricing_models')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.map(option => {
          option.order = PRICING_MODEL_OPTIONS[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.feeOptions = temp
      })
    axios.get('/public/v2/values/fee_ranges')
      .then(({ data }) => {
        let temp = this.mapValues(data, false, 'label')
        temp = temp.map(option => {
          option.order = PRICING_RANGE_OPTIONS[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.feeRangeOptions = temp
      })
    axios.get('/public/v2/values/languages')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.languageOptions = temp
      })
    axios.get('/public/v2/values/seniorities')
      .then(({ data }) => {
        let temp = this.mapValues(data.filter(s => s.label !== 'Not important'), true)
        temp = temp.map(option => {
          option.order = SENIORITY_OPTIONS_ORDER[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.seniorityOptions = temp
      })

    axios.get('/public/v2/values/goals')
      .then(({ data }) => {
        this.goalOptions = data
      })
  },
  methods: {
    mapValues (data) {
      const dataArr = data.map(option => {
        option.value = option.id
        option.text = option.label
        return option
      })
      return dataArr
    },
    uploadPic (event) {
      const form = new FormData()
      form.set('file', event)
      axios.post('/public/v2/upload', form)
        .then(({ data }) => {
          this.agency.logo = data
        })
    },
    saveAgency () {
      // // Since some certain date is selected. Make available from from that date
      if (this.availability === 'available_from') {
        this.availability = this.available_from
      }
      if (this.$store.state.auth.user.role === 'admin') {
        axios.put(`/admin/v2/users/${this.agency.users[0].id}`, this.agency.users[0])
          .then(() => {
            this.$store.dispatch('auth/self')
          })
      } else {
        axios.put(`/v2/users/${this.agency.users[0].id}`, this.agency.users[0])
          .then(() => {
            this.$store.dispatch('auth/self')
          })
      }
      // // Since some certain date is selected. Make available from from that date
      if (this.agency.availability === 'available_from') {
        this.agency.availability = this.available_from
      }
      axios.put(`/v2/agencies/${this.agency.id}`, this.agency)
        .then(() => {
          this.$store.dispatch('auth/self')

            .then(() => {
              this.$bvModal.msgBoxOk('Your profile has been updated!', {
                title: ' ',
                size: 'md',
                buttonSize: 'lg',
                headerClass: 'p-4 text-center',
                centered: true,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
              })
                .then(() => {
                  this.$store.dispatch('auth/self')
                  this.$router.push(`/agency/${this.$route.params.id}`)
                })
            })
        })
    },
    toggleGoal (goal) {
      const index = this.agency.goals.indexOf(goal)
      if (index > -1) {
        const tempGoals = this.agency.goals
        tempGoals.splice(index, 1)
        this.$set(this.agency, 'goals', tempGoals)
      } else {
        const tempGoals = this.agency.goals
        tempGoals.push(goal)
        this.$set(this.agency, 'goals', tempGoals)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .borderless-tags {
    border: none;
    box-shadow: none;
    padding: 0;
  }
  .select-small-font {
    font-size: 0.9rem;
  }
  .content-holder {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
    border-radius: 12px;
  }
  .agency_logo {
    display: none;
    cursor: pointer;
  }
  .agency_logo_upload {
    height: 200px;
    width: 200px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 100%;
  }
  .answer-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px #EDE1DF;
    border: 2px solid transparent;
    border-radius: 12px;
    color: #323031;
    font-size: 16px;
    min-height: 80px;
    &:hover {
      box-shadow: 0px 0px 10px rgba(216, 216, 216, 0.5);
    }
    &.active {
      border: 2px solid #EE8B7B;
    }
  }
  .second-headline {
    font-size: 18px;
    line-height: 140%;
  }
</style>
